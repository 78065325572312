import { getPublicEnv } from '@/utils/env'

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
  if (window.gtag) {
    window.gtag('config', getPublicEnv('NEXT_PUBLIC_GTM_CODE'), {
      page_path: url,
    })
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
// Not used in this project
export const event = ({ action, category, label, value }: {action: string, category: string, label: string, value: string }) => {
  if (window.gtag) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value,
    })
  }
}
