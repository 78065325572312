import { useRouter } from 'next/router'
import { useEffect } from 'react'

export const useOnRouteChangeComplete = (onRouteComplete: (url: string) => void) => {
  const router = useRouter()

  useEffect(() => {
    router.events.on('routeChangeComplete', onRouteComplete)

    return () => {
      router.events.off('routeChangeComplete', onRouteComplete)
    }
  }, [onRouteComplete, router])
}

export const useOnRouteChangeStart = (onRouteStart: (url: string) => void) => {
  const router = useRouter()

  useEffect(() => {
    router.events.on('routeChangeStart', onRouteStart)

    return () => {
      router.events.off('routeChangeStart', onRouteStart)
    }
  }, [onRouteStart, router])
}
