import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { Lazy } from './lazy'

const globalReactPluginLazy = new Lazy<ReactPlugin>(() => new ReactPlugin())

export const getApplicationInsightsReactPlugin = () => globalReactPluginLazy.Value

let globalApplicationInsights: Lazy<ApplicationInsights> | undefined

export const getApplicationInsightsSdk = (connectionString: string, reactPlugin: ReactPlugin) => {
  if (globalApplicationInsights === undefined) {
    globalApplicationInsights = new Lazy<ApplicationInsights>(() => {
      const applicationInsights = new ApplicationInsights({
        config: {
          connectionString,
          extensions: [reactPlugin],
          enableAutoRouteTracking: true,
        },
      })
      applicationInsights.loadAppInsights(false)
      return applicationInsights
    })
  }

  return globalApplicationInsights.Value
}
