import { Sites } from '@/queries/schema.generated'

const PROD_ENVIRONMENTS = ['production', 'prd']

export const IS_PROD_BUILD = () => process.env.NODE_ENV === 'production'
export const IS_PROD_DEPLOYMENT = () => PROD_ENVIRONMENTS.some((env) => env === process.env.NEXT_PUBLIC_DEPLOYMENT_ENV)

// TODO: We should make this non-nullable
interface PublicEnv {
  NEXT_PUBLIC_APPLICATIONINSIGHTS_CONNECTION_STRING?: string
  NEXT_PUBLIC_PUBLICHOSTNAME?: string
  NEXT_PUBLIC_API_ENDPOINT?: string
  NEXT_PUBLIC_FRONTEND_TOKEN?: string
  NEXT_PUBLIC_MAPBOX_KEY?: string
  NEXT_PUBLIC_GOOGLE_MAPS_KEY?: string
  NEXT_PUBLIC_GTM_CODE?: string
  NEXT_PUBLIC_DEPLOYMENT_ENV?: string
  NEXT_PUBLIC_SITE?: string
  NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITEKEY?: string
  NEXT_PUBLIC_SEARCH_ENDPOINT?: string
}

export const getNonce = () => 'WmNFB1'

export const getPublicEnv = (key: keyof PublicEnv) => getPublicConfig()[key]
export const getPublicConfig = (): PublicEnv => {
  return {
    NEXT_PUBLIC_APPLICATIONINSIGHTS_CONNECTION_STRING: process.env.NEXT_PUBLIC_APPLICATIONINSIGHTS_CONNECTION_STRING,
    NEXT_PUBLIC_PUBLICHOSTNAME: process.env.NEXT_PUBLIC_PUBLICHOSTNAME,
    NEXT_PUBLIC_API_ENDPOINT: process.env.NEXT_PUBLIC_API_ENDPOINT,
    NEXT_PUBLIC_FRONTEND_TOKEN: process.env.NEXT_PUBLIC_FRONTEND_TOKEN,
    NEXT_PUBLIC_MAPBOX_KEY: process.env.NEXT_PUBLIC_MAPBOX_KEY,
    NEXT_PUBLIC_GOOGLE_MAPS_KEY: process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY,
    NEXT_PUBLIC_GTM_CODE: process.env.NEXT_PUBLIC_GTM_CODE,
    NEXT_PUBLIC_DEPLOYMENT_ENV: process.env.NEXT_PUBLIC_DEPLOYMENT_ENV,
    NEXT_PUBLIC_SITE: process.env.NEXT_PUBLIC_SITE,
    NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITEKEY: process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITEKEY,
    NEXT_PUBLIC_SEARCH_ENDPOINT: process.env.NEXT_PUBLIC_SEARCH_ENDPOINT,
  }
}

export const getNextPublicSite = () => getPublicEnv('NEXT_PUBLIC_SITE') as Sites
