import Router from 'next/router'

import 'nprogress/nprogress.css'
import { useEffect } from 'react'

const useEffectAsync = async () => {
  if (process.env.NODE_ENV === 'production') {
    return
  }

  const NProgress = await import('nprogress')

  Router.events.on('routeChangeStart', () => NProgress.start())
  Router.events.on('routeChangeComplete', () => NProgress.done())
  Router.events.on('routeChangeError', () => NProgress.done())
}

const useNProgress = () => {
  useEffect(() => {
    void useEffectAsync()
  }, [])
}

export default useNProgress
