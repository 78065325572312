import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { sha256 } from 'crypto-hash'
import generatedIntrospection from '@/queries/apollofragmentmatcher'
import { getPublicEnv } from '@/utils/env'

const DEFAULT_NEXT_PUBLIC_GRAPHQL_ENDPOINT = '/graphql'
const persistedQueryNotFoundErrorMessage = 'PersistedQueryNotFound'

const currentSiteLink = setContext((operation, request) => {
  if (!operation.variables.site) {
    // eslint-disable-next-line no-param-reassign
    operation.variables.site = getPublicEnv('NEXT_PUBLIC_SITE')
  }

  return {
    request,
  }
})

const getServerAuthLink = (preview: boolean) =>
  setContext((_, { headers }) => {
    const token = process.env.FRONTEND_TOKEN
    return {
      headers: {
        ...headers,
        ...(preview ? { version: 'draft' } : {}),
        authorization: `Bearer ${token}`,
      },
    }
  })

const getClientAuthLink = (preview: boolean) => {
  return setContext((_, { headers }) => {
    const token = getPublicEnv('NEXT_PUBLIC_FRONTEND_TOKEN')
    return {
      headers: {
        ...headers,
        ...(preview ? { version: 'draft' } : {}),
        authorization: `Bearer ${token}`,
      },
    }
  })
}

const getErrorLink = () =>
  onError(({ operation, graphQLErrors, networkError }) => {
    console.warn(`[ApolloClient] An error when retreiving ${operation.operationName}`)
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        // eslint-disable-next-line no-console
        if (message !== persistedQueryNotFoundErrorMessage) {
          console.log(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`)
        }
      })
    }

    if (networkError) {
      // eslint-disable-next-line no-console
      console.log(`[Network error]: ${networkError}`)
    }
  })

const getPersistedQueryLink = () =>
  createPersistedQueryLink({
    sha256,
    useGETForHashedQueries: true,
  })

const getServerHttpLink = () =>
  createHttpLink({
    uri: process.env.GRAPHQL_ENDPOINT,
    includeUnusedVariables: true,
  })

const getClientHttpLink = () =>
  createHttpLink({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT ?? DEFAULT_NEXT_PUBLIC_GRAPHQL_ENDPOINT,
    includeUnusedVariables: true,
  })

export const createApolloClient = (preview: boolean) => {
  const isServer = typeof window === 'undefined'

  const apolloLink = ApolloLink.from([
    isServer ? getServerAuthLink(preview) : getClientAuthLink(preview),
    currentSiteLink,
    getErrorLink(),
    getPersistedQueryLink(),
    isServer ? getServerHttpLink() : getClientHttpLink(),
  ])

  const cache: InMemoryCache = new InMemoryCache({
    possibleTypes: generatedIntrospection.possibleTypes,
  })

  return new ApolloClient({
    ssrMode: !isServer,
    link: apolloLink,
    cache,
    credentials: 'same-origin',
  })
}
