// See ref: https://support.google.com/google-ads/answer/6299296#setup_steps&zippy=%2Cstep-edit-your-website-to-collect-and-save-the-click-id
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

const GoogleClickIdKey = 'googleClickId'

function getExpiryRecord(value: string) {
  const expiryPeriod = 90 * 24 * 60 * 60 * 1000 // 90 day expiry in milliseconds

  const expiryDate = new Date().getTime() + expiryPeriod
  return {
    value,
    expiryDate,
  }
}

export const useCurrentGoogleClickId = () => {
  const [googleClickIdRecord, setGoogleClickIdRecord] = useState(null)

  useEffect(() => {
    if (localStorage) {
      setGoogleClickIdRecord(JSON.parse(localStorage.getItem(GoogleClickIdKey)))
    }
  }, [])

  const isGoogleClickIdValid = googleClickIdRecord && new Date().getTime() < googleClickIdRecord?.expiryDate

  const googleClickId = isGoogleClickIdValid ? googleClickIdRecord?.value : null

  return { googleClickId }
}

export const useGoogleClickIdStorage = () => {
  const { query } = useRouter()
  const googleClickIdParam = query?.gclid?.toString()
  const googleClickSrcParam = query?.gclsrc?.toString()
  const isGoogleClickSrcValid = !googleClickSrcParam || googleClickSrcParam.includes('aw')

  const googleClickIdRecord = googleClickIdParam && isGoogleClickSrcValid ? getExpiryRecord(googleClickIdParam) : null

  useEffect(() => {
    if (localStorage && googleClickIdRecord) {
      localStorage.setItem(GoogleClickIdKey, JSON.stringify(googleClickIdRecord))
    }
  }, [googleClickIdRecord])

  return googleClickIdRecord
}
