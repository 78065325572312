import React, { ReactNode, useRef } from 'react'
import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { getPublicEnv } from '@/utils/env'
import { getApplicationInsightsReactPlugin, getApplicationInsightsSdk } from '../../utils/getApplicationInsightsSdk'

interface Props {
  children: ReactNode
}

const ApplicationInsightsProvider = ({ children }: Props) => {
  const NEXT_PUBLIC_APPLICATIONINSIGHTS_CONNECTION_STRING = getPublicEnv(
    'NEXT_PUBLIC_APPLICATIONINSIGHTS_CONNECTION_STRING',
  )

  const reactPluginRef = useRef<ReactPlugin>(
    NEXT_PUBLIC_APPLICATIONINSIGHTS_CONNECTION_STRING ? getApplicationInsightsReactPlugin() : null,
  )

  useRef(
    reactPluginRef.current !== null
      ? getApplicationInsightsSdk(
          NEXT_PUBLIC_APPLICATIONINSIGHTS_CONNECTION_STRING,
          reactPluginRef.current,
        )
      : undefined,
  )

  if (!reactPluginRef.current) {
    return <>{children}</>
  }

  return <AppInsightsContext.Provider value={reactPluginRef.current}>{children}</AppInsightsContext.Provider>
}

export default ApplicationInsightsProvider
