interface PushToDataLayerParams {
  event: string
  product?: string
  type: string
  'contact-interest'?: string
}

interface GTMProps {
  event: string
  virtual_url: string
  virtual_title: string
}

export const gtmPageView = (url: string, title: string) => {
  const pageEvent: GTMProps = {
    event: 'vpv',
    virtual_url: url,
    virtual_title: title,
  }

  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push(pageEvent)
  }

  return pageEvent
}

export const pushToDataLayer = (params: PushToDataLayerParams) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    return window.dataLayer.push(params)
  }

  return null
}
