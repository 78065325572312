import '../css/normalize.css'
import '../css/style.css'

import React, { useEffect } from 'react'
import type { AppProps } from 'next/app'
import { ApolloProvider } from '@apollo/client'
import { theme } from '@damen/ui'
import Modal from 'react-modal'
import { ThemeProvider } from 'styled-components'
import dynamic from 'next/dynamic'
import useNProgress from '@/hooks/nProgress'
import { mapKey, useApollo } from '@/lib/apollo/useApollo'
import { pageview as gtagPageView } from '@/lib/gtag'
import { gtmPageView } from '@/lib/gtm'
import { useGoogleClickIdStorage } from '@/hooks/googleClickId'
import { useQuerystringModal as useModelQueryString } from '@/hooks/modal/queryString'
import { getPublicConfig } from '@/utils/env'
import ApplicationInsightsProvider from '@/components/ApplicationInsightsProvider'
import { useOnRouteChangeComplete } from '@/hooks/router/useOnRouteChange'
import StoryblokPreviewBar from '@/components/StoryblokPreviewBar'
import PortalRoot from '@/components/UI/PortalRoot'

Modal.setAppElement('#__next') // Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)

declare global {
  interface Window {
    dataLayer: Record<string, any>[]
    storyblok: unknown
    gaGlobal: {
      vid: string
    }
    recaptchaOptions: {
      useRecaptchaNet: boolean
    }
  }
}

const StoryblokBridgeLoader = dynamic(() => import('../lib/storyblok/preview/index'))

const MyApp = ({ Component, pageProps }: AppProps<{
  initialApolloState: unknown
  preview?: boolean
  dedicatedPreview?: boolean
}>) => {
  const apolloClient = useApollo(pageProps.initialApolloState)
  useGoogleClickIdStorage()
  useNProgress()

  const { NEXT_PUBLIC_MAPBOX_KEY } = getPublicConfig()

  // Initialize Mapbox
  useEffect(() => {
    if (NEXT_PUBLIC_MAPBOX_KEY) {
      mapKey(NEXT_PUBLIC_MAPBOX_KEY)
    }
  }, [NEXT_PUBLIC_MAPBOX_KEY])

  useOnRouteChangeComplete((url) => {
    // eslint-disable-next-line no-console
    console.debug(`Visited page ${url}`)

    gtagPageView(url)
    gtmPageView(url, document.title)
  })

  useEffect(() => {
    window.recaptchaOptions = {
      useRecaptchaNet: true,
    }
  }, [])

  useModelQueryString()

  return (
    <ApplicationInsightsProvider>
      <ApolloProvider client={apolloClient}>
        <PortalRoot>
          <ThemeProvider theme={theme}>
            <Component {...pageProps} />
            {pageProps.dedicatedPreview && <StoryblokPreviewBar livePreview={false} />}
            {pageProps.preview && !pageProps.dedicatedPreview && (
              <>
                <StoryblokBridgeLoader />
                <StoryblokPreviewBar livePreview={pageProps.preview} />
              </>
            )}
          </ThemeProvider>
        </PortalRoot>
      </ApolloProvider>
    </ApplicationInsightsProvider>
  )
}

export default MyApp
