import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { css, styled } from 'styled-components'

const Container = styled.div(
  ({ theme }) => css`
    position: fixed;
    --margin: 24px;
    left: var(--margin);
    bottom: var(--margin);
    max-width: min(500px, calc(100% - var(--margin) * 2));
    z-index: 100;
    background: #1b243f;
    color: #fff;
    letter-spacing: 0;
    display: flex;
    border-radius: 8px;

    @media screen and (min-width: ${theme.legacyMediaQueries.tablet}px) {
      --margin: 40px;
    }
  `,
)
const Text = styled.div`
  padding: 18px 24px;
  font-size: 12px;
`
const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
`
const HideAlert = styled.a`
  color: #00b3b0;
  cursor: pointer;
`
const Button = styled.button`
  color: #00b3b0;
  border: 0;
  padding: 24px;
  line-height: 1;
  position: relative;
  cursor: pointer;

  ::before {
    content: '';
    position: absolute;
    left: 0;
    top: 18px;
    height: calc(100% - 18px * 2);
    background: #00b3b0;
    width: 1px;
  }
`

const StoryblokPreviewBar = ({ livePreview }: any) => {
  // Allow hiding the alert in case it overlaps interface
  const [hideAlert, setHideAlert] = useState(false)
  // Reset on page change since we don't want the user to forget
  const { asPath } = useRouter()
  useEffect(() => {
    setHideAlert(false)
  }, [asPath])

  if (hideAlert) {
    return null
  }

  const handleExit = async () => {
    await fetch(`/api/exit-preview`)
    window.location.reload()
  }

  const previewtitle = livePreview ? 'Live preview' : 'Preview website'
  const previewContent = livePreview
    ? 'You are viewing this site in preview mode.'
    : 'You are viewing draft content on the preview website.'

  return (
    <Container>
      <Text>
        <Title>{previewtitle}</Title>
        {previewContent} <HideAlert onClick={() => setHideAlert(true)}>Hide alert</HideAlert>
      </Text>
      {livePreview && (
        <Button type="button" onClick={void handleExit} title="Exit preview mode">
          &#x2715;
        </Button>
      )}
    </Container>
  )
}

export default StoryblokPreviewBar
