import React, { createContext, ReactNode, useContext, useLayoutEffect, useState } from 'react'

const PortalContext = createContext<HTMLDivElement | null>(null)

const usePortalRoot = () => useContext(PortalContext)
// TODO: Replace this entire solution with one that is SSR compatible
export const usePortalElement = () => {
  const portalRoot = usePortalRoot()
  const [elem] = useState<HTMLDivElement>(() => document.createElement('div'))

  if (!portalRoot) {
    throw new Error('Missing PortalRoot from React tree')
  }

  useLayoutEffect(() => {
    if (!portalRoot) {
      return
    }

    portalRoot.appendChild(elem)

    // eslint-disable-next-line consistent-return
    return () => {
      portalRoot.removeChild(elem)
    }
  }, [portalRoot, elem])

  return elem
}

interface Props {
  children: ReactNode
}

const PortalRoot = ({ children }: Props) => {
  const [portal, setPortal] = useState<HTMLDivElement | null>(null)

  return (
    <PortalContext.Provider value={portal}>
      {children}

      <div ref={setPortal} />
    </PortalContext.Provider>
  )
}

export default PortalRoot
